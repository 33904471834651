<template>
  <c-box w="full">
    <Breadcrumb :items="breadcrumbs" />
    <hr
      v-chakra
      mt="20px"
    >
    <c-text
      mt="30px"
      font-family="Montserrat"
      font-size="24px"
      font-weight="700"
      line-height="36px"
    >
      Tambah Notifikasi
    </c-text>
    <FormCreate
      v-model="notifikasi"
      v-chakra
      mt="30px"
    />
    <c-stack
      mt="30px"
      is-inline
      spacing="20px"
    >
      <c-button
        rounded="full"
        h="62px"
        w="289px"
        as="router-link"
        :to="{ name: 'admin.notifikasi' }"
      >
        Batal
      </c-button>
      <c-button
        variant-color="primary"
        rounded="full"
        h="62px"
        w="289px"
        @click.prevent="submit()"
      >
        Simpan
      </c-button>
    </c-stack>
  </c-box>
</template>

<script>
import FormCreate from './form-create.vue'
import Breadcrumb from '@/components/breadcrumb'

export default {
  name: 'AdminNotifikasiCreate',
  components: { Breadcrumb, FormCreate },
  data() {
    return {
      notifikasi: null,
    }
  },
  computed: {
    breadcrumbs() {
      return [
        {
          label: 'Manajemen Notifikasi',
          href: this.$router.resolve({ name: 'admin.notifikasi' }).href,
        },
        { label: 'Tambah Notifikasi', isCurrent: true },
      ]
    },
  },
  methods: {
    async submit() {
      this.$toast({
        title: 'Membuat notifikasi',
        variant: 'left-accent',
        position: 'bottom-right',
      })
      let notif = await this.$store.dispatch(
        'admNotifikasi/create',
        this.notifikasi,
      )
      this.$toast({
        title: 'Notifikasi berhasil dibuat',
        status: 'success',
        variant: 'left-accent',
        position: 'bottom-right',
      })
      await this.$router.replace({
        name: 'admin.notifikasi-detail',
        params: { notifikasiId: notif.id },
      })
    },
  },
}
</script>
